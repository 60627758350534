import { jwtDecode } from 'jwt-decode';
import mitt from 'mitt';
import { createContext, useContext } from 'react';
import { QueryObserverBaseResult } from 'react-query';
import {
  readStorageItem,
  removeStorageItem,
  useStorageValue,
  writeStorageItem,
} from 'shared/helpers/LocalStorage';
import { NormalizedPermissions } from 'shared/types/permission';
import { WhoAmI } from 'shared/types/user';

const TOKEN_STORAGE_KEY = 'token';
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
const SHIPPER_ID_FOR_ADMIN_KEY = 'shipper_id_for_admin';

function setAdminCredentials(token: string, shipperId: string) {
  writeStorageItem(TOKEN_STORAGE_KEY, token);

  writeStorageItem(SHIPPER_ID_FOR_ADMIN_KEY, shipperId);

  window.dispatchEvent(new Event('storage'));
}

function setSSOCredentials(token: string) {
  writeStorageItem(TOKEN_STORAGE_KEY, token);
}

export function useAppToken() {
  return useStorageValue(TOKEN_STORAGE_KEY);
}

export function useShipperIdForAdmin() {
  return useStorageValue(SHIPPER_ID_FOR_ADMIN_KEY);
}

export function readAppToken() {
  return readStorageItem(TOKEN_STORAGE_KEY);
}

export function readRefreshToken() {
  return readStorageItem(REFRESH_TOKEN_STORAGE_KEY);
}

export function setRefreshToken(token: string) {
  writeStorageItem(REFRESH_TOKEN_STORAGE_KEY, token);
  // For chrome extension
  window.dispatchEvent(new Event('storage'));
}

function parseToken(token: string) {
  return jwtDecode(token);
}

export function readAppTokenDecoded() {
  const token = readAppToken();
  return token ? parseToken(token) : undefined;
}

export function readShipperIdForAdmin() {
  return readStorageItem(SHIPPER_ID_FOR_ADMIN_KEY);
}

export function setAppToken(token: string) {
  writeStorageItem(TOKEN_STORAGE_KEY, token);
  // For chrome extension
  window.dispatchEvent(new Event('storage'));
}

export function deleteAppToken() {
  removeStorageItem(TOKEN_STORAGE_KEY);
  // For chrome extension
  window.dispatchEvent(new Event('storage'));
}

export function deleteRefreshToken() {
  removeStorageItem(REFRESH_TOKEN_STORAGE_KEY);
  // For chrome extension
  window.dispatchEvent(new Event('storage'));
}

export function deleteShipperIdForAdmin() {
  removeStorageItem(SHIPPER_ID_FOR_ADMIN_KEY);
}

type AuthState = 'unauthorized' | 'authorized' | 'pending';

export interface AuthContext {
  user?: WhoAmI;
  isAdmin: boolean;
  isSuperUser: boolean;
  refetch: QueryObserverBaseResult['refetch'];
  logout: () => void;
  authState: AuthState;
  permissions?: Partial<NormalizedPermissions>;
}

export const AuthEmitter = mitt();

export function emitLogout() {
  AuthEmitter.emit('logout');
}

export const Context = createContext<AuthContext | null>(null);

export function useUserState() {
  const context = useContext(Context);

  if (context === null) {
    throw new Error('useUserState is used outside AuthProvider');
  }

  return context;
}

export interface LogoutOptions {
  skipLogoutEndpointCall?: boolean;
}

// inject token from query params
function authFromUrlParams() {
  const url = new URL(window.location.href);
  const urlParams = url.searchParams;
  const shipperIdForAdmin = urlParams.get('shipper_id_for_admin');
  const token = urlParams.get('token');
  const isSSOAuth = urlParams.get('sso_auth');

  if (isSSOAuth && token) {
    setSSOCredentials(token);
    urlParams.delete('token');
    urlParams.delete('sso_auth');
    window.history.replaceState(null, '', url);
  } else if (shipperIdForAdmin && token) {
    setAdminCredentials(token, shipperIdForAdmin);
    urlParams.delete('token');
    urlParams.delete('shipper_id_for_admin');
    window.history.replaceState(null, '', url);
  } else if (token) {
    setAppToken(token);
    urlParams.delete('token');
    window.history.replaceState(null, '', url);
  }
}

// reset password has token but it is not user JWT token
if (window.location.pathname !== '/reset-password') {
  authFromUrlParams();
}
