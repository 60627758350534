import { IconButton, Link, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import { Inline, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Alert, Button, TextBox } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getIsTokenRefreshEnabled } from 'shared/api/API';
import { setAppToken, setRefreshToken } from 'shared/data/AppUserState';
import { APIError } from 'shared/errors/APIError';
import styled from 'styled-components';
import { setDeviceGuid } from './core/DeviceGuid';
import { VerificationActions } from './core/VerificationActions';
import { useVerification } from './core/VerificationStorage';
import { useAuthAPI } from './data/LoginAPI';
import {
  VerificationResponseDTO,
  verifyAccountSchema,
} from './data/LoginFormDTO';
import { LoginLayout } from './LoginLayout';

const Container = styled.div`
  word-wrap: break-word;
  padding: 24px;
  hyphens: auto;
`;

export function VerifyAccountPage() {
  const navigate = useNavigate();
  const { checkVerificationCode } = useAuthAPI();
  const { addSnackbar } = useSnackbarStack();
  const {
    email,
    phoneNumber,
    username,
    verificationChannel = 'email',
    attemptSessionGuid = '',
    removeAttemptSessionGuid,
  } = useVerification();
  const [channel, setChannel] = useState(verificationChannel);

  const formik = useFormikEnhanced({
    initialValues: {
      code: '',
      username,
      channel,
      attempt_session_guid: attemptSessionGuid,
    },
    validationSchema: verifyAccountSchema,
    onSubmit: (values) => {
      return checkVerificationCode({
        ...values,
        channel,
      });
    },
    onSubmitSuccess: (response: VerificationResponseDTO, values) => {
      setAppToken(response.token);

      if (getIsTokenRefreshEnabled() && response.refresh_token) {
        setRefreshToken(response.refresh_token);
      }

      if (values.username) {
        setDeviceGuid(values.username, response.device_guid);
      }
      removeAttemptSessionGuid();
    },
    onSubmitFailure: (error: APIError) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <LoginLayout>
      <Container>
        <FormikProvider value={formik}>
          <Form aria-label="verify">
            <Stack space="large">
              <Stack space="small">
                <Inline space="xsmall" verticalAlign="center">
                  <IconButton
                    aria-label="go back"
                    onClick={() => navigate('/signin')}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h2">
                    Check your {channel === 'sms' ? 'messages!' : 'inbox!'}
                  </Typography>
                </Inline>

                <Typography>
                  We&#8217;ve sent you a temporary 6-digit login code at{' '}
                  <b>{channel === 'sms' ? phoneNumber : email}.</b> Please enter
                  this code to login to your account.
                </Typography>
              </Stack>

              <FormikTextField
                label="Verification Code"
                name="code"
                fullWidth={true}
                inputProps={{ thousandSeparator: false, maxLength: 6 }}
              />

              <VerificationActions channel={channel} setChannel={setChannel}>
                <Button
                  type="submit"
                  size="large"
                  fullWidth={true}
                  disabled={!formik.dirty || !formik.isValid}
                  pending={formik.isSubmitting}
                >
                  Log In
                </Button>
              </VerificationActions>

              <Alert severity="info">
                <Stack space="xsmall">
                  <TextBox color="primary">
                    Two-Factor Authentication (2FA) is now required to log in to
                    Super Dispatch.
                  </TextBox>
                  <Link
                    color="primary"
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.superdispatch.com/en/articles/9001156-shipper-tms-2fa"
                  >
                    Learn more
                  </Link>
                </Stack>
              </Alert>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </LoginLayout>
  );
}
