import { hasOnlyDigits } from 'shared/utils/StringUtils';
import { yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType, string } from 'yup';

export type LoginFormDTO = InferType<typeof loginFormSchema>;
export const loginFormSchema = yupObject({
  username: string().required('Enter username'),
  password: string().required('Enter password'),
  device_guid: string().nullable().optional(),
});

export const loginSSOFormSchema = yupObject({
  email: string()
    .trim()
    .required('Enter email')
    .email('Enter valid email. Example: name@company.com'),
});

export type LoginResponseDTO =
  | {
      token: string;
      mfa_channel?: undefined;
      phone_number?: undefined;
      email?: undefined;
      attempt_session_guid?: undefined;
      /**
       * will be present if token refresh enabled
       */
      refresh_token?: string;
    }
  | {
      token?: undefined;
      mfa_channel: 'email' | 'sms';
      phone_number?: string;
      email: string;
      attempt_session_guid: string;
    };

export const loginResponseSchema = yupObject({
  token: string().optional(),
  /**
   * will be present if token refresh enabled
   */
  refresh_token: string().optional(),
  mfa_channel: string().oneOf(['email', 'sms']).optional(),
  phone_number: string().optional(),
  email: string().email().optional(),
  attempt_session_guid: string().optional(),
});

export type VerifyAccountDTO = InferType<typeof verifyAccountSchema>;
export const verifyAccountSchema = yupObject({
  code: string()
    .nullable()
    .required('Enter Verification Code')
    .length(6, 'Your code must be composed of 6 digits')
    .test('only digits', 'Your code must contain only digits', hasOnlyDigits),
  username: yupString().notRequired(),
  channel: yupString(),
  attempt_session_guid: string(),
});

export type VerificationResponseDTO = InferType<
  typeof verificationResponseSchema
>;
export const verificationResponseSchema = yupObject({
  token: yupString(),
  /**
   * will be present if token refresh enabled
   */
  refresh_token: yupString().optional(),
  device_guid: yupString(),
});

export type ResendVerificationCodeDTO = InferType<typeof resendCodeSchema>;
export const resendCodeSchema = yupObject({
  username: yupString().required(),
  channel: yupString().required(),
  attempt_session_guid: string(),
});

export type ResendVerificationResponseDTO = InferType<
  typeof resendCodeResponseSchema
>;
export const resendCodeResponseSchema = yupObject({
  mfa_channel: string().oneOf(['email', 'sms']),
  phone_number: string().optional(),
  email: string().email().optional(),
});
