import { useVerification } from 'auth/core/VerificationStorage';
import { useMemo } from 'react';
import { getIsTokenRefreshEnabled, useAPI } from 'shared/api/API';
import { readRefreshToken } from 'shared/data/AppUserState';
import {
  LoginFormDTO,
  LoginResponseDTO,
  loginResponseSchema,
  resendCodeResponseSchema,
  ResendVerificationCodeDTO,
  ResendVerificationResponseDTO,
  VerificationResponseDTO,
  verificationResponseSchema,
  VerifyAccountDTO,
} from './LoginFormDTO';

export function useAuthAPI() {
  const { request, requestResource, requestJSON } = useAPI();
  const { removeVerification } = useVerification();

  return useMemo(
    () => ({
      login: (json: LoginFormDTO) => {
        return requestResource<LoginResponseDTO>(
          'POST /auth/login',
          (data) => loginResponseSchema.cast(data) as LoginResponseDTO,
          { json },
        );
      },
      logout: () => {
        removeVerification();

        if (getIsTokenRefreshEnabled()) {
          return request('POST /auth/logout', {
            json: {
              refresh_token: readRefreshToken(),
            },
          });
        }

        return request('POST /internal/users/logout');
      },
      checkVerificationCode: (json: VerifyAccountDTO) => {
        return requestResource<VerificationResponseDTO>(
          'POST /auth/verification/check-code',
          (data) => verificationResponseSchema.cast(data),
          { json },
        );
      },
      resendVerificationCode: (json: ResendVerificationCodeDTO) => {
        return requestResource<ResendVerificationResponseDTO>(
          'POST /auth/verification/resend-code',
          (data) => resendCodeResponseSchema.cast(data),
          { json },
        );
      },
    }),
    [removeVerification, request, requestResource, requestJSON],
  );
}
